import React from 'react';

function ProductDisplay({ title, priceList }) {
  return (
    <div style={{ border: '1px solid #ccc', padding: '1rem', marginBottom: '1rem' }}>
      <h2>{title}</h2>
      {priceList && priceList.length > 0 ? (
        <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
          {priceList.map(([price, size], idx) => (
            <li key={idx}>
              ${price} for {size} g
            </li>
          ))}
        </ul>
      ) : (
        <p>Out of Stock.</p>
      )}
    </div>
  );
}

export default ProductDisplay;
