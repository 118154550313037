import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import ProductDisplay from './matcha_object'; // Adjust path as needed
import { X } from 'react-feather'

const Jessica = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState('');

  // Function to fetch data for a given URL
  const fetchData = async (url) => {
    let type = url.includes("sazen") ? "SZ" : "IP";

    try {
      const response = await fetch('/.netlify/functions/scrape', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          website: url,
          type: type,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const data = await response.json();
      return { url, title: data.title, prices: data.prices };
    } catch (err) {
      console.error('Error fetching scrape data:', err);
      throw err;
    }
  };

  // Load saved URLs from cookies and refetch their data on mount
  useEffect(() => {
    const savedResults = Cookies.get('searchResults');
    if (savedResults) {
      const urls = JSON.parse(savedResults).map((result) => result.url);
      setLoading(true);

      Promise.all(urls.map((url) => fetchData(url)))
        .then((fetchedResults) => {
          setSearchResults(fetchedResults);
        })
        .catch((err) => {
          console.error('Error refetching data:', err);
          setError('Failed to refetch some or all data.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    Cookies.set('searchResults', JSON.stringify(searchResults), { expires: 7 }); // Expires in 7 days
  }, [searchResults]);

  const fetchScrape = async () => {
    if (!url) {
      setError('Please enter a valid URL.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const result = await fetchData(url);
      setSearchResults((prevResults) => [...prevResults, result]);
      setUrl(''); // Clear the input field
    } catch (err) {
      console.error('Error fetching scrape data:', err);
      setError(err.message || 'Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const removeItem = (indexToRemove) => {
    setSearchResults((prevResults) =>
      prevResults.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="projectPage">
      <h1>Jessica!</h1>
      <p>This is Jessica's Matcha Page</p>

      <input
        type="text"
        value={url}
        placeholder="Enter product URL"
        onChange={(e) => setUrl(e.target.value)}
      />
      <button onClick={fetchScrape} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Scrape'}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div>
        <h2>Search Results</h2>
        {searchResults.length === 0 ? (
          <p>No data fetched yet.</p>
        ) : (
          searchResults.map((result, index) => (
            <div key={index} style={{ marginBottom: '20px', position: 'relative' }}>
              <h3>{result.title || `Result ${index + 1}`}</h3>
              <p><strong>URL:</strong> {result.url}</p>
              <ProductDisplay title={result.title} priceList={result.prices} />
              <X
                onClick={() => removeItem(index)}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    transform: 'scale(.5)', // Makes the button larger
                    cursor: 'pointer',
                  }}
                
                />
              
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Jessica;
