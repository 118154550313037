import React from 'react';
import BackButton from '../BackButton';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProjectFileComponent from './projectFile';



const Projects = () => {
    return (
        <div className='projectPage'>
            <BackButton></BackButton>
          <h1>Projects!</h1>
          <p>Work in Progress</p>
          <ProjectFileComponent
              id="Hi"
              title="ASL"
              summary="This project is cool"
            />
            
        </div>
      );
};

export default Projects;
