import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Writing from './pages/Writing';
import Projects from './pages/components/Portfolio/Projects';
import Favorites from './pages/Favorites';
import Tenets from './pages/Tenets';
import ProtectedRoute from './pages/components/ProtectedRoute';
import ProjectFileComponent from './pages/components/Portfolio/projectFile';
import Matcha_page from './pages/components/Portfolio/Jessica/MatchaProject/Matcha_page';


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects"element={<Projects />}> 
          <Route path=":id" element={<ProjectFileComponent />} />
      </Route>
      <Route path="/writing"element={<Writing />}> 
      </Route>
      <Route path="/tenets" element={<Tenets />} />
      <Route
        path="/favorites"
        element={
          <ProtectedRoute password="jessica">
            <Favorites />
          </ProtectedRoute>
        }
      />
      <Route path="/jessica" element={<Matcha_page />}> 
      </Route>
    </Routes>
  );
};

export default App;

