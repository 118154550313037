import React from 'react';
import { Link } from 'react-router-dom';
import "./projectFile.css"; // Import the CSS file for styling


const ProjectFileComponent = ({title, summary, id}) => {
    return (
        <div className="ProjectFileComponent">
            {/* <Link to="/projects/"{id}>Writing</Link> */}

            <div className="summary">
               <text>{summary}</text>
            </div>
        </div>
    )
}

export default ProjectFileComponent;